<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewUserForm"
        ref="addNewUserForm"
        class="w-100 pt-6"
        v-model="isFormValid"
        @submit.prevent="submitNewUserForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Imię"
              placeholder="Wpisz imię"
              :rules="[rules.required, rules.word]"
              v-model="user.firstName"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Nazwisko"
              placeholder="Wpisz nazwisko"
              :rules="[rules.required, rules.word]"
              v-model="user.lastName"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="user.roleIds"
              :items="userRoles"
              multiple
              item-text="name"
              item-value="id"
              label="Rola"
              placeholder="Wybierz rolę"
              outlined
              dense
            >
              <template #selection="{ index, item }">
                <MultiselectChoices
                  :index="index"
                  :label="item.name"
                  :array-length="user.roleIds.length"
                  :visible-items="3"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Login"
              placeholder="Wpisz login użytkownika"
              :rules="[rules.required, rules.noSpaces]"
              validate-on-blur
              v-model="user.login"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Hasło"
              placeholder="Wpisz hasło użytkownika"
              :rules="[rules.required, rules.password]"
              validate-on-blur
              v-model="user.password"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewUserForm"
      >
        Dodaj użytkownika
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import MultiselectChoices from '../Elements/MultiselectChoices.vue'

export default {
  components: {
    DialogFormWrapper,
    MultiselectChoices
  },
  data () {
    return {
      rules,
      isFormValid: false,
      user: {
        firstName: null,
        lastName: null,
        roleIds: null,
        login: '',
        password: null,
      }
    }
  },
  computed: {
    ...mapState({
      userRoles: state => state.core.filters.userRoles,
      isProcessing: state => state.users.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewUser: 'users/addNewItem',
      getUsers: 'users/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewUserForm () {
      this.$refs.addNewUserForm.validate()
      if (this.isFormValid) {
        this.addNewUser({ params: this.user })
          .then(() => {
            this.closeDialog()
          })
      }
    }
  }
}
</script>
