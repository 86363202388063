import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"addNewUserForm",staticClass:"w-100 pt-6",attrs:{"id":"addNewUserForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitNewUserForm.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Imię","placeholder":"Wpisz imię","rules":[_vm.rules.required, _vm.rules.word]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Nazwisko","placeholder":"Wpisz nazwisko","rules":[_vm.rules.required, _vm.rules.word]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.userRoles,"multiple":"","item-text":"name","item-value":"id","label":"Rola","placeholder":"Wybierz rolę","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('MultiselectChoices',{attrs:{"index":index,"label":item.name,"array-length":_vm.user.roleIds.length,"visible-items":3}})]}}]),model:{value:(_vm.user.roleIds),callback:function ($$v) {_vm.$set(_vm.user, "roleIds", $$v)},expression:"user.roleIds"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Login","placeholder":"Wpisz login użytkownika","rules":[_vm.rules.required, _vm.rules.noSpaces],"validate-on-blur":""},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Hasło","placeholder":"Wpisz hasło użytkownika","rules":[_vm.rules.required, _vm.rules.password],"validate-on-blur":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"type":"submit","form":"addNewUserForm"}},[_vm._v(" Dodaj użytkownika ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }